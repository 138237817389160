/* You can add global styles to this file, and also import other style files */
/* @import '~ngx-toastr/toastr.css'; */
@import 'ngx-toastr/toastr';

* {
  font-family: futura-medium, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  background-color: #f0f1f3;
}

.max-width {
  max-width: 940px;
}
.shadow {
  -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.16);
}

.subtle {
  color: #6f6f6f;
}

.error {
  color: red;
}

.has-error {
  border: 1px solid rgb(216, 12, 12) !important;
  color: rgb(230, 14, 14) !important;
}

span[ng-reflect-router-link] {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}

/* Underline hover animation */
/* .underline-hover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #28ca7e;
  content: "";
  transition: width 0.2s;
  margin-bottom: -2px;
}

.underline-hover:hover {
  color: #0d0d0d;
} */

/* .underline-hover:hover:after {
  width: 100%;
} */

/* bootstrap overrides */
.active {
  border-bottom: 2px solid #28ca7e;
}

.form-control:focus {
  border-color: #28ca7e;
  box-shadow: 0 0 0 0.15rem #28ca7e;
}

.nav-link {
  padding-top: 0;
  padding-bottom: 0;
}

/* .form-control {
    border: none;
} */
html,
body {
  height: 100%;
}
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

/* angular material overrides  */
.mat-row:hover {
  background-color: rgba(144, 238, 144, 0.623) !important;
  cursor: default;
  transition: background-color 0.3s ease-in;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 !important;
}

mat-form-field {
  width: 100%;
}

// .mat-raised-button {
//   color: white !important;
// }

mat-form-field {
  padding-bottom: 0.5em;
}

.selected {
  background-color: #28ca7e;
  color: white;
}

.tbl-w-75 {
  max-width: 75px;
}

.tbl-w-100 {
  max-width: 100px;
}

.mat-cell {
  word-wrap: break-word !important;
  white-space: unset !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.green {
  color: #4caf50;
}

.red {
  color: #f44336;
}

.blue {
  color: #448aff;
}

.sp-btn {
  cursor: pointer;
}

.sp-btn:active {
  cursor: pointer;
}

.mw-500 {
  max-width: 500px;
}

table {
  max-width: 100%;
}
